
import React from "react";

// Internal Import
import { LoginMemberDataRes } from "../../../utils/structure/output";

// External Import
import { useNavigate } from "react-router-dom";
import { ReactSession } from 'react-client-session';
import { AppBar, Avatar, Box, Button, Popover, Tooltip, Typography } from "@mui/material";
import { getAvatarName } from "../../../utils";

export interface AppBarAccountComponentProps {
    page: string
    account: undefined | LoginMemberDataRes,
    setAccount: (value: undefined | LoginMemberDataRes) => void
}
export const AppbarAccountComponent: React.FC<AppBarAccountComponentProps> = ({ page, account, setAccount }) => {
    let navigate = useNavigate();
    const [scrolled, setScrolled] = React.useState<boolean>(false);
    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 50) { setScrolled(true) }
        else if (scrolled <= 50) { setScrolled(false) }
    };
    window.addEventListener('scroll', toggleVisible);

    // Search
    // const [search, setSearch] = React.useState<string>("");
    // const handleChangeSearch = (event: any) => { setSearch(event.target.value); }
    // const handleSearch = () => { }

    // Popover Menu
    // const [anchorElMenu, setAnchorElMenu] = React.useState<HTMLButtonElement | null>(null);
    // const handleCloseMenu = () => { setAnchorElMenu(null); };
    // const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    //     setAnchorElMenu(event.currentTarget);
    // };
    // const openMenu = Boolean(anchorElMenu);

    // Popover Profile
    const [anchorElProfile, setAnchorElProfile] = React.useState<HTMLButtonElement | null>(null);
    const handleCloseProfile = () => { setAnchorElProfile(null); };
    const handleClickProfile = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElProfile(event.currentTarget);
    };
    const openProfile = Boolean(anchorElProfile);

    const handleChangeProfile = () => {
        navigate('/change-profile');
    }

    const handleLogout = () => {
        setAccount(undefined);
        ReactSession.set(process.env.REACT_APP_AUTH, undefined);
        navigate('/login', { replace: true });
    }

    const onClickMenu = (item: string) => {
        if (item === 'simulasi-pembayaran') {
            window.open("https://eregcr.com/eRegCRAdmin/auth/api?act=simulasiPembayaran&dev=Desktop&memberId=" + account?.id_member, "_self");
            return
        }
        navigate('/' + item)
    }

    const imgLogoLandscape = `/assets/logo_landscape_${process.env.REACT_APP_PROJECT_CODE}.png`;
    if (account === undefined) {
        return null;
    }
    return (
        <Box sx={{ marginBottom: '3.9em' }}>
            <AppBar position="fixed" component='nav' style={{
                background: '#fff',
                alignItems: 'center',
                justifyContent: 'center',
                boxShadow: scrolled ? "0 3px 5px rgba(57, 63, 72, 0.3)" : "unset"
            }}>
                <Box sx={{
                    // mx: '2.5em',
                    width: '90%',
                    height: '3.8em',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}>
                    {process.env.REACT_APP_PROJECT_CODE=="cmr"?(
                        <Box 
                            component="img" 
                            src={imgLogoLandscape} 
                            sx={{ height: '3em' }} 
                            alt="Image Logo Landscape" 
                        />
                    ):(
                        <Box component="img" src={imgLogoLandscape} alt="Image Logo Landscape" sx={{
                            height: { xs: '2.1em', sm: '2.3em', md: '2.4em', lg: '2.7em', xl: '2.8em' }
                        }} />
                    )}
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                    }}>
                        {/* <Box sx={{
                            p: '0.5em',
                            width: '16em',
                            height: '1.2em',
                            color: '#008060',
                            fontSize: '0.8em',
                            background: '#fff',
                            borderRadius: '10em',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px',
                            display: { xs: 'none', sm: 'flex', md: 'flex', lg: 'flex', xl: 'flex' }
                        }}>
                            <input
                                value={search}
                                placeholder='Search...'
                                disabled
                                onChange={handleChangeSearch}
                                style={{ 
                                    flex: 1, 
                                    border: 'none', 
                                    outline: 'none', 
                                    paddingLeft: '0.5em', 
                                    paddingRight: '0.5em', 
                                    backgroundColor: '#ffffff'
                                }}
                            />
                            <Box sx={{
                                mx: '0.2em',
                                width: '0.05em',
                                height: '1.3em',
                                background: '#A1A1AC',
                            }} />
                            <SearchIcon sx={{ cursor: 'pointer', color: '#000000' }} onClick={handleSearch} />
                        </Box> */}
                        {/* <Box sx={{
                            width: '0.05em',
                            height: '1.3em',
                            background: '#A1A1AC',
                            ml: { xs: '0.5em', lg: '1em' },
                            marginInline: { xs: '0.75em', lg: '1.3em' },
                            display: { xs: 'none', sm: 'flex', md: 'flex', lg: 'flex', xl: 'flex' }
                        }} /> */}
                        {/* <span
                            onClick={handleClickMenu}
                            aria-describedby={'menu-popover'}
                            style={{
                                display: 'flex',
                                cursor: 'pointer',
                                alignItems: 'center',
                                flexDirection: 'row',
                            }}
                        >
                            <Typography sx={{
                                fontWeight: 500,
                                color: '#008060',
                                fontFamily: 'Roboto',
                                textTransform: 'capitalize',
                                fontSize: { xs: '0.75em', sm: '0.75em', md: '0.8em', lg: '0.85em', xl: '0.9em' },
                            }}>{page}</Typography>
                            <KeyboardArrowDownIcon sx={{
                                color: "#008060",
                                width: { xs: '0.7em', sm: '0.8em', md: '0.9em', lg: '1em', xl: '1em' },
                                height: { xs: '0.7em', sm: '0.8em', md: '0.9em', lg: '1em', xl: '1em' },
                            }} />
                        </span> */}
                        {/* <Box sx={{
                            width: '0.05em',
                            height: '1.3em',
                            background: '#A1A1AC',
                            ml: { xs: '0.5em', lg: '1em' },
                            mr: { xs: '0.75em', lg: '1.3em' },
                        }} /> */}
                        <Tooltip title="Profile">
                            <span
                                onClick={handleClickProfile}
                                aria-describedby={'profile-popover'}
                            >
                                <Avatar
                                    sx={{
                                        width: '3em',
                                        height: '3em',
                                        color: '#008060',
                                        cursor: 'pointer',
                                        background: '#fff',
                                        fontSize: { xs: '0.65em', sm: '0.7em', md: '0.75em', lg: '0.8em', xl: '0.8em' },
                                        boxShadow: 'rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px',
                                    }}
                                >{getAvatarName(account.fullname)}</Avatar>
                            </span>
                        </Tooltip>
                    </Box>
                </Box>
            </AppBar>
            {/* <Popover
                open={openMenu}
                id='menu-popover'
                anchorEl={anchorElMenu}
                onClose={handleCloseMenu}
                sx={{ marginTop: '0.5em' }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Typography sx={{
                    fontSize: '0.85em',
                    fontWeight: 'bold',
                    fontFamily: 'Roboto'
                }}>
                    <Box sx={{ px: '1.25em', py: '0.75em' }}>
                        {page !== 'home' && (
                            <Typography onClick={() => onClickMenu("Home")} sx={{
                                mb: '1em',
                                fontSize: '1em',
                                cursor: 'pointer',
                                fontWeight: 'bold',
                                fontFamily: 'Roboto'
                            }}>Home</Typography>
                        )}
                        <Typography onClick={() => onClickMenu("register-event")} sx={{
                            mb: '1em',
                            fontSize: '1em',
                            cursor: 'pointer',
                            fontWeight: 'bold',
                            fontFamily: 'Roboto'
                        }}>Register Event</Typography>
                        <Typography onClick={() => onClickMenu("simulasi-pembayaran")} sx={{
                            mb: '1em',
                            fontSize: '1em',
                            cursor: 'pointer',
                            fontWeight: 'bold',
                            fontFamily: 'Roboto'
                        }}>Simulasi Pembayaran</Typography>
                        <Typography onClick={() => onClickMenu("table-kpr")} sx={{
                            fontSize: '1em',
                            cursor: 'pointer',
                            fontWeight: 'bold',
                            fontFamily: 'Roboto'
                        }}>Table KPR</Typography>
                        <Typography onClick={() => onClickMenu("contact-us")} sx={{
                            mb: '0.5em',
                            cursor: 'pointer',
                            fontSize: '0.85em',
                            fontWeight: 'bold',
                            fontFamily: 'Roboto'
                        }}>Contact Us</Typography>
                    </Box>
                </Typography>
            </Popover> */}
            {account && (
                <Popover
                    open={openProfile}
                    id='profile-popover'
                    sx={{ mt: '0.5em' }}
                    anchorEl={anchorElProfile}
                    onClose={handleCloseProfile}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <Box sx={{ px: '1.25em', py: '0.75em' }}>
                        <Typography sx={{
                            fontSize: '0.9em',
                            fontWeight: 'bold',
                            fontFamily: 'Roboto'
                        }}>
                            {account.fullname}<br />{"(" + account.office + ")"}
                        </Typography>
                        <Box sx={{
                            my: '0.75em',
                            width: '100%',
                            height: '0.05em',
                            borderRadius: '10em',
                            background: '#A1A1AC',
                        }} />

                        <Box>
                            <Typography onClick={() => onClickMenu("")} sx={{
                                fontSize: '0.7em',
                                cursor: 'pointer',
                                fontFamily: 'Roboto',
                                color: '#ffffff',
                                textAlign: 'center',
                                borderRadius: '1em',
                                backgroundColor: '#868686',
                                mt: '1.5em',
                                mb: '0.75em',
                                py: '0.25em',
                            }}>Home</Typography>
                            <Typography onClick={() => onClickMenu("event")} sx={{
                                fontSize: '0.7em',
                                cursor: 'pointer',
                                fontFamily: 'Roboto',
                                color: '#ffffff',
                                textAlign: 'center',
                                borderRadius: '1em',
                                backgroundColor: '#868686',
                                mb: '0.75em',
                                py: '0.25em',
                            }}>Event</Typography>
                            {/* <Typography onClick={() => onClickMenu("register-event")} sx={{
                                mb: '1em',
                                fontSize: '1em',
                                cursor: 'pointer',
                                fontWeight: 'bold',
                                fontFamily: 'Roboto'
                            }}>Register Event</Typography> */}
                            <Typography onClick={() => onClickMenu("simulasi-pembayaran")} sx={{
                                fontSize: '0.7em',
                                cursor: 'pointer',
                                fontFamily: 'Roboto',
                                color: '#ffffff',
                                textAlign: 'center',
                                borderRadius: '1em',
                                backgroundColor: '#868686',
                                mb: '0.75em',
                                py: '0.25em',
                            }}>Simulasi Pembayaran</Typography>
                            <Typography onClick={() => onClickMenu("table-kpr")} sx={{
                                fontSize: '0.7em',
                                cursor: 'pointer',
                                fontFamily: 'Roboto',
                                color: '#ffffff',
                                textAlign: 'center',
                                borderRadius: '1em',
                                backgroundColor: '#868686',
                                mb: '0.75em',
                                py: '0.25em',
                            }}>Table KPR</Typography>
                            {/* <Typography onClick={() => onClickMenu("contact-us")} sx={{
                            mb: '0.5em',
                            cursor: 'pointer',
                            fontSize: '0.85em',
                            fontWeight: 'bold',
                            fontFamily: 'Roboto'
                        }}>Contact Us</Typography> */}
                        </Box>

                        <Box sx={{
                            mt: '1.5em',
                            mb: '0.25em',
                            display: 'flex',
                            flexDirection: 'row'
                        }}>
                            <Button
                                fullWidth
                                size='small'
                                type='button'
                                color='success'
                                variant="contained"
                                onClick={handleChangeProfile}
                                sx={{
                                    mr: '0.5em',
                                    fontSize: '0.75em',
                                    borderRadius: '10em',
                                    backgroundColor: '#008060',
                                    textTransform: 'capitalize',
                                }}
                            >Change Profile</Button>
                            <Button
                                size='small'
                                type='button'
                                color='error'
                                variant="contained"
                                onClick={handleLogout}
                                sx={{
                                    fontSize: '0.75em',
                                    borderRadius: '10em',
                                    backgroundColor: '#D31D1D',
                                    textTransform: 'capitalize',
                                }}
                            >Logout</Button>
                        </Box>
                    </Box>
                </Popover>
            )}
        </Box>
    );
};