import React from 'react';

// Internal Import
import './index.css';
import { FooterComponent } from '../../components/footer';
import { HelmetComponent } from '../../components/helmet';

// External Import
import { ReactSession } from 'react-client-session';

// MUI Import
import { Box, Stack, Typography } from "@mui/material"
import { AppbarAccountComponent } from '../../components/appbar/account';
import { LoginMemberDataRes } from '../../utils/structure/output';

interface TableKPRScreenProps { }
export const TableKPRScreen: React.FC<TableKPRScreenProps> = (props) => {

    const [account, setAccount] = React.useState<undefined | LoginMemberDataRes>(undefined);

    React.useEffect(() => {
        let dtAccount: undefined | LoginMemberDataRes = ReactSession.get(process.env.REACT_APP_AUTH)
        setAccount(dtAccount);
    }, [])

    return (
        <Stack sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            paddingBottom: { xs: '3.4em', sm: '3.6em', md: '3.8em', lg: '4em', xl: '4.2em' },
        }}>
            <HelmetComponent />
            <AppbarAccountComponent page={'home'} account={account} setAccount={setAccount} />
            <Box sx={{
                pl: '5%',
                pr: '5%',
                width: '90%',
                backgroundColor: '#ffffff',
                py: { xs: '1em', sm: '1.5em', md: '1.75em', lg: '2.5em', xl: '2.5em' },
            }}>
                <Typography sx={{
                    mb: '1.5em',
                    color: '#000000',
                    fontWeight: 'bold',
                    fontFamily: 'Roboto',
                    fontSize: { xs: '0.8em', sm: '0.9em', md: '1em', lg: '1.25em', xl: '1.25em' },
                }}>Table KPR - Update 8 September 2023</Typography>

                <table id='table-kpr'>
                    <thead>
                        <tr><th scope="col" rowSpan={2}>Bank</th>
                            <th scope="col" colSpan={5} align='center'>Suku Bunga Tetap</th>
                            <th scope="col" rowSpan={2}>Floating</th>
                            <th scope="col" rowSpan={2}>Ket</th>
                        </tr>
                        <tr>
                            <th scope="col">1 Tahun</th>
                            <th scope="col">2 Tahun</th>
                            <th scope="col">3 Tahun</th>
                            <th scope="col">5 Tahun</th>
                            <th scope="col">10 Tahun</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>ARTHA GRAHA</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>12,50%</td>
                            <td>-</td>
                        </tr>
                        <tr>
                            <td>BCA</td>
                            <td>2,75%</td>
                            <td>3,73%</td>
                            <td>3,70%</td>
                            <td>4,70%</td>
                            <td>6,80%</td>
                            <td>-</td>
                            <td>s/d 08 Oct 2023</td>
                        </tr>
                        <tr>
                            <td>BJB</td>
                            <td>-</td>
                            <td>-</td>
                            <td>5,25%</td>
                            <td>-</td>
                            <td>-</td>
                            <td>13,50%</td>
                            <td>-</td>
                        </tr>
                        <tr>
                            <td>BNI</td>
                            <td>-</td>
                            <td>-</td>
                            <td>3,55%</td>
                            <td>4,55%</td>
                            <td>-</td>
                            <td>-</td>
                            <td>s/d 30 Sep 2023</td>
                        </tr>
                        <tr>
                            <td>BRI</td>
                            <td>5,75%</td>
                            <td>5,75%</td>
                            <td>5,75%</td>
                            <td>9,05%</td>
                            <td>11,05%</td>
                            <td>-</td>
                            <td>-</td>
                        </tr>
                        <tr>
                            <td>BSI</td>
                            <td>8,50%</td>
                            <td>8,50%</td>
                            <td>8,50%</td>
                            <td>8,50%</td>
                            <td>8,50%</td>
                            <td>-</td>
                            <td>s/d 31 Oct 2023</td>
                        </tr>
                        <tr>
                            <td>BTN</td>
                            <td>-</td>
                            <td>-</td>
                            <td>3,47%</td>
                            <td>5,47%</td>
                            <td>-</td>
                            <td>-</td>
                            <td>s/d 30 Agt 2023</td>
                        </tr>
                        <tr>
                            <td>CCBI</td>
                            <td>-</td>
                            <td>4,75%</td>
                            <td>5,00%</td>
                            <td>5,75%</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                        </tr>
                        <tr>
                            <td>CIMB NIAGA</td>
                            <td>-</td>
                            <td>-</td>
                            <td>3,68%</td>
                            <td>5,99%</td>
                            <td>8,49%</td>
                            <td>-</td>
                            <td>-</td>
                        </tr>
                        <tr>
                            <td>DANAMON</td>
                            <td>-</td>
                            <td>-</td>
                            <td>3,88%</td>
                            <td>5,08%</td>
                            <td>-</td>
                            <td>-</td>
                            <td>s/d 30 Agt 2023</td>
                        </tr>
                        <tr>
                            <td>MANDIRI</td>
                            <td>2,55%</td>
                            <td>-</td>
                            <td>3,45%</td>
                            <td>4,55%</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                        </tr>
                        <tr>
                            <td>MAYBANK</td>
                            <td>-</td>
                            <td>-</td>
                            <td>4,25%</td>
                            <td>4,75%</td>
                            <td>8,50%</td>
                            <td>-</td>
                            <td>-</td>
                        </tr>
                        <tr>
                            <td>OCBC NISP</td>
                            <td>2,88%</td>
                            <td>4,50%</td>
                            <td>4,25%</td>
                            <td>3,88%</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                        </tr>
                        <tr>
                            <td>PANIN</td>
                            <td>4,20%</td>
                            <td>4,20%</td>
                            <td>4,20%</td>
                            <td>7,78%</td>
                            <td>9,78%</td>
                            <td>-</td>
                            <td>-</td>
                        </tr>
                        <tr>
                            <td>PANIN SYARIAH</td>
                            <td>7,75%</td>
                            <td>7,75%</td>
                            <td>7,75%</td>
                            <td>7,75%</td>
                            <td>8,75%</td>
                            <td>12,50%</td>
                            <td>-</td>
                        </tr>
                        <tr>
                            <td>PERMATA</td>
                            <td>-</td>
                            <td>-</td>
                            <td>4,25%</td>
                            <td>4,50%</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                        </tr>
                        <tr>
                            <td>UOB</td>
                            <td>-</td>
                            <td>-</td>
                            <td>4,50%</td>
                            <td>5,50%</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                        </tr>
                        <tr>
                            <td>NOBU</td>
                            <td>-</td>
                            <td>-</td>
                            <td>4,69%</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                        </tr>
                        <tr>
                            <td>PROLINE</td>
                            <td>12,50%</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                        </tr>


                    </tbody>
                </table>

            </Box>

            <FooterComponent />
        </Stack>
    )
}