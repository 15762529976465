import React from 'react';


// Internal Import
import { AppbarComponent } from '../../../components/appbar';
import { HelmetComponent } from '../../../components/helmet';
import { ChangeProfileReq } from '../../../utils/structure/input';
import { StatusProps, SnackbarProps, SnackbarComponent } from '../../../components/snackbar';
import { DataRes, OfficeRes, DefaultRes, LoginMemberDataRes } from '../../../utils/structure/output';

// External Import
import { useNavigate } from 'react-router-dom';
import { ReactSession } from 'react-client-session';
import axios, { AxiosError, AxiosResponse } from 'axios';

// MUI Import
import { Box, Stack, Button, Typography, Select, FormControl, MenuItem, InputLabel, SelectChangeEvent, TextField, Stepper, StepLabel, Step, StepContent, Modal } from '@mui/material';


// Image Import
const imgLogo = `/assets/logo_landscape_${process.env.REACT_APP_PROJECT_CODE}.png`;

interface ListKantor { id: string, name: string }

interface ChangeProfileScreenProps { }
export const ChangeProfileScreen: React.FC<ChangeProfileScreenProps> = (props) => {

    let navigate = useNavigate();

    const [loading, setLoading] = React.useState<boolean>(false);

    const [account, setAccount] = React.useState<undefined | LoginMemberDataRes>(undefined);

    // Snackbar
    const [snackbar, setSnackbar] = React.useState<SnackbarProps>({ open: false, status: undefined, message: '' });
    const handleOpenSnackbar = (newStatus: StatusProps, newMessage: string, time: number) => {
        setSnackbar({ open: true, status: newStatus, message: newMessage });
        setTimeout(() => { handleCloseSnackbar(); }, 5000);
    };
    const handleCloseSnackbar = () => { setSnackbar({ open: false, status: undefined, message: '' }); };

    // Stepper
    const steps = ["Choose Member Group & Office", "Data Information", "Review & Submit Data"];
    const [activeStep, setActiveStep] = React.useState<number>(0);
    const handleBack = () => { 
        setActiveStep((prevActiveStep) => prevActiveStep - 1); 
    };
    const handleNext = () => {
        if (loading) { return }
        if (activeStep == 0) {
            if (idOffice == '0') { handleOpenSnackbar('error', 'Office is required', 5000); return; }
            setActiveStep(activeStep + 1);
        }
        if (activeStep == 1) {
            if (nameKtp == '') { handleOpenSnackbar('error', 'Name is required', 5000); return; }
            if (nomorKtp == '') { handleOpenSnackbar('error', 'KTP is required', 5000); return; }
            if (nomorPhone1 == '') { handleOpenSnackbar('error', 'No Handphone 1 is required', 5000); return; }
            if (emailAddress == '') { handleOpenSnackbar('error', 'Email is required', 5000); return; }
            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailAddress)) { handleOpenSnackbar('error', 'Invalid email!', 5000); return; }
            if (nomorPhone1 == nomorPhone2) { handleOpenSnackbar('error', 'No HandPhone 1 must not be the same as No HandPhone 2', 5000); return; }
            setActiveStep(activeStep + 1);
        }
        if (activeStep == 2) {
            setLoading(true);
            let params: ChangeProfileReq = {
                ktp_number: nomorKtp,
                id_office: idOffice,
                office: Office,
                phone_number1: nomorPhone1,
                phone_number2: nomorPhone2,
                email_address: emailAddress
            }
            postData(params);
        }
        
    };

    // Form
    const [idOffice, setIdOffice] = React.useState<string>("0");
    const [Office, setOffice] = React.useState<string>("");
    
    const [nameKtp, setNameKtp] = React.useState<string>("");
    const [nomorKtp, setNomorKtp] = React.useState<string>("");
    const [nomorPhone1, setNomorPhone1] = React.useState<string>("");
    const [nomorPhone2, setNomorPhone2] = React.useState<string>("");
    const [emailAddress, setEmailAddress] = React.useState<string>("");
    const selectIdOffice = (event: SelectChangeEvent) => { setIdOffice(event.target.value as string); };
    const changeNameKtp = (event: React.ChangeEvent<HTMLInputElement>) => { setNameKtp(event.target.value as string); }
    const changeNomorKtp = (event: React.ChangeEvent<HTMLInputElement>) => {
        let inputValue = (event.target.value as string).replace(/\D/g, "");
        if (inputValue[0] == '0') { inputValue = inputValue.substring(1); }
        if (inputValue.length <= 16) { setNomorKtp(inputValue); }
    }
    const changeNomorPhone1 = (event: React.ChangeEvent<HTMLInputElement>) => {
        let inputValue = (event.target.value as string);
        if (inputValue.length > 0) {
            const formatValue = (event.target.value as string).replace(/\D/g, "");
            if (formatValue[0] != "0") { inputValue = "0" + formatValue; }
        }
        setNomorPhone1(inputValue);
    }
    const changeNomorPhone2 = (event: React.ChangeEvent<HTMLInputElement>) => {
        let inputValue = (event.target.value as string);
        if (inputValue.length > 0) {
            const formatValue = (event.target.value as string).replace(/\D/g, "");
            if (formatValue[0] != "0") { inputValue = "0" + formatValue; }
        }
        setNomorPhone2(inputValue);
    }
    const changeEmailAddress = (event: React.ChangeEvent<HTMLInputElement>) => { setEmailAddress(event.target.value as string) }
    const [listKantor, setListKantor] = React.useState<any[]>([{ id: "0", name: "- Choose Office -" }]);

    // Change Profile
    const postData = async (requestData: ChangeProfileReq) => {
        try {
            let url: string = process.env.REACT_APP_API_BASE_URL! + process.env.REACT_APP_PATH_CHANGE_PROFILE!;
            const post: AxiosResponse = await axios.post(url, requestData, { 
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', Authorization: 'Bearer ' + account?.token },
            });
            let response: DefaultRes = post.data;

            if (response.status == 200 && account != undefined) {
                let newAccount: LoginMemberDataRes = {
                    id_member: account?.id_member,
                    id_member_enc: account?.id_member_enc,
                    fullname: account?.fullname,
                    ktp: requestData.ktp_number,
                    email: requestData.email_address,
                    phone: account?.phone,
                    phone2: requestData.phone_number2,
                    mid: account?.mid,
                    tkn: account?.tkn,
                    m_group: account?.m_group,
                    id_office: requestData.id_office,
                    office: requestData.office,
                    is_update: account?.is_update,
                    token: account?.token,
                }
                setAccount(newAccount);
                ReactSession.set(process.env.REACT_APP_AUTH, newAccount);
                setActiveStep(activeStep + 1);
                
                setLoading(false);
                handleOpenSnackbar('success', response.message, 3000);
                setTimeout(() => { navigate(-1) }, 3000);
            } else {
                setLoading(false);
                handleOpenSnackbar('error', response.message, 3000);
            }
        } catch (error) {
            setLoading(false);
            const axiosError = error as AxiosError;
            let errResponse: DefaultRes | any = axiosError.response?.data;
            handleOpenSnackbar('error', errResponse?.message, 5000);
        }
    }

    // Load Office
    const getData = async (dtAccount: LoginMemberDataRes) => {
        try {
            let link = process.env.REACT_APP_API_BASE_URL! + process.env.REACT_APP_PATH_OFFICES! + '?group_member=' + dtAccount.m_group.toLowerCase() + '&ereg_project='+ process.env.REACT_APP_PROJECT_ID_EREG!+'&code_project='+process.env.REACT_APP_PROJECT_CODE!+'&citralink_project='+process.env.REACT_APP_PROJECT_ID_CTRLNK!
            const response: AxiosResponse = await axios.get(link, { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' } });
            let dtOffices: OfficeRes = response.data;
            if (dtOffices.status === 200) {
                let arrListKantor: ListKantor[] = [{ id: "0", name: "- Choose Office -" }];
                dtOffices.data.map((item: DataRes) => {
                    let objListKantor: ListKantor = { id: item.id, name: item.name }
                    arrListKantor.push(objListKantor);
                    return null;
                });
                setListKantor(arrListKantor);
                setIdOffice(dtAccount.id_office);
                setOffice(dtAccount.office);
                setNameKtp(dtAccount.fullname);
                setNomorKtp(dtAccount.ktp);
                setNomorPhone1(dtAccount.phone);
                setNomorPhone2(dtAccount.phone2);
                setEmailAddress(dtAccount.email);
            } else {
                setListKantor([{ id: "0", name: "- Choose Office -" }]);
            }
        } catch (error) {
            setListKantor([{ id: "0", name: "- Choose Office -" }]);
            handleOpenSnackbar('error', "Terjadi kendala! Silahkan coba lagi!", 5000);
        }
    }

    React.useEffect(() => {
        let dtAccount: LoginMemberDataRes = ReactSession.get(process.env.REACT_APP_AUTH)
        setAccount(dtAccount);
        getData(dtAccount);
    }, []);


    return (
        <Stack sx={{
            flex: 1,
            display: 'flex',
            minHeight: '100vh',
            alignItems: 'center',
            flexDirection: 'column',
            backgroundColor: '#F1F1F7'
        }}>
            <HelmetComponent />
            <AppbarComponent />

            <SnackbarComponent 
                snackbar={snackbar} 
                handleCloseSnackbar={handleCloseSnackbar} 
            />

            <Box sx={{
                my: '3em',
                height: '100%',
                width: { xs: '90%', sm: '85%', md: '80%', lg: '65%', xl: '70%' }
            }}>
                <Typography sx={{
                    mb: '0.2em',
                    fontWeight: '500',
                    textAlign: 'center',
                    fontFamily: 'Open Sans',
                    fontSize: { xs: '1.6em', sm: '1.8em', md: '2em', lg: '2em', xl: '2.2em' }
                }}>Change Profile</Typography>
            </Box>

            <Box sx={{
                mb: '3.5em',
                borderRadius: '0.8em',
                backgroundColor: '#ffffff',
                boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px",
                width: { xs: '90%', sm: '80%', md: '60%', lg: '40%', xl: '30%' }
            }}>
                <Stepper activeStep={activeStep} orientation="vertical" sx={{ px: '1.5em', py: '1em' }}>
                    {steps.map((step, index) => (
                        <Step key={index}>
                            <StepLabel>{step}</StepLabel>
                            <StepContent>
                                {index === 0 && (
                                    <Box sx={{ my: '1.5em' }}>
                                        <FormControl fullWidth>
                                            <InputLabel id="group">Kantor</InputLabel>
                                            <Select
                                                id="office"
                                                label="office"
                                                value={idOffice}
                                                labelId="group"
                                                onChange={selectIdOffice}
                                            >
                                                {listKantor.map((item, index) => {
                                                    return (<MenuItem key={index} value={item.id}>{item.name}</MenuItem>);
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                )}
                                {index === 1 && (
                                    <Box sx={{ my: '1.5em' }}>
                                        <TextField
                                            disabled
                                            fullWidth
                                            type='text'
                                            value={nameKtp}
                                            variant="outlined"
                                            id="outlined-basic"
                                            onChange={changeNameKtp}
                                            label="*Nama Sales (sesuai KTP)*"
                                            placeholder="*Nama Sales (sesuai KTP)"
                                        />
                                        <TextField
                                            fullWidth
                                            type='text'
                                            sx={{ mt: '2em' }}
                                            value={nomorPhone1}
                                            variant="outlined"
                                            id="outlined-basic"
                                            label="*No HandPhone 1"
                                            onChange={changeNomorPhone1}
                                            placeholder="*No HandPhone 1"
                                        />
                                        <TextField
                                            fullWidth
                                            type='text'
                                            sx={{ mt: '2em' }}
                                            value={nomorPhone2}
                                            variant="outlined"
                                            id="outlined-basic"
                                            label="No HandPhone 2"
                                            onChange={changeNomorPhone2}
                                            placeholder="*No HandPhone 2"
                                        />
                                        <TextField
                                            fullWidth
                                            type='text'
                                            label="*Email"
                                            sx={{ mt: '2em' }}
                                            variant="outlined"
                                            id="outlined-basic"
                                            placeholder="*Email"
                                            value={emailAddress}
                                            onChange={changeEmailAddress}
                                        />
                                        <Typography sx={{
                                            mt: '3em',
                                            fontStyle: 'italic',
                                            fontFamily: 'Roboto',
                                            fontSize: { xs: '0.65em', sm: '0.65em', md: '0.7em', lg: '0.8em', xl: '0.8em' },
                                        }}>{"* Data wajib diisi"}</Typography>
                                    </Box>
                                )}
                                {index == 2 && (
                                    <Box sx={{ mt: '1em', mb: '1.5em' }}>
                                        <Box>
                                            <Typography sx={{
                                                fontWeight: 'bold',
                                                fontFamily: 'Open Sans',
                                                fontSize: { xs: '0.55em', sm: '0.6em', md: '0.65em', lg: '0.7em', xl: '0.7em' },
                                            }}>Nama Kantor</Typography>
                                            <Typography sx={{
                                                fontFamily: 'Open Sans',
                                                fontSize: { xs: '0.75em', sm: '0.8em', md: '0.85em', lg: '1em', xl: '1em' },
                                            }}>{(listKantor.find(of => of.id === idOffice) || {}).name}</Typography>
                                        </Box>
                                        <Box sx={{ mt: '0.5em' }}>
                                            <Typography sx={{
                                                fontWeight: 'bold',
                                                fontFamily: 'Open Sans',
                                                fontSize: { xs: '0.55em', sm: '0.6em', md: '0.65em', lg: '0.7em', xl: '0.7em' },
                                            }}>Nama Sales</Typography>
                                            <Typography sx={{
                                                fontFamily: 'Open Sans',
                                                fontSize: { xs: '0.75em', sm: '0.8em', md: '0.85em', lg: '1em', xl: '1em' },
                                            }}>{nameKtp}</Typography>
                                        </Box>
                                        <Box sx={{ mt: '0.5em' }}>
                                            <Typography sx={{
                                                fontWeight: 'bold',
                                                fontFamily: 'Open Sans',
                                                fontSize: { xs: '0.55em', sm: '0.6em', md: '0.65em', lg: '0.7em', xl: '0.7em' },
                                            }}>No KTP Sales</Typography>
                                            <Typography sx={{
                                                fontFamily: 'Open Sans',
                                                fontSize: { xs: '0.75em', sm: '0.8em', md: '0.85em', lg: '1em', xl: '1em' },
                                            }}>{nomorKtp}</Typography>
                                        </Box>
                                        <Box sx={{ mt: '0.5em' }}>
                                            <Typography sx={{
                                                fontWeight: 'bold',
                                                fontFamily: 'Open Sans',
                                                fontSize: { xs: '0.55em', sm: '0.6em', md: '0.65em', lg: '0.7em', xl: '0.7em' },
                                            }}>No Handphone 1</Typography>
                                            <Typography sx={{
                                                fontFamily: 'Open Sans',
                                                fontSize: { xs: '0.75em', sm: '0.8em', md: '0.85em', lg: '1em', xl: '1em' },
                                            }}>{nomorPhone1}</Typography>
                                        </Box>
                                        <Box sx={{ mt: '0.5em' }}>
                                            <Typography sx={{
                                                fontWeight: 'bold',
                                                fontFamily: 'Open Sans',
                                                fontSize: { xs: '0.55em', sm: '0.6em', md: '0.65em', lg: '0.7em', xl: '0.7em' },
                                            }}>No Handphone 2</Typography>
                                            <Typography sx={{
                                                fontFamily: 'Open Sans',
                                                fontSize: { xs: '0.75em', sm: '0.8em', md: '0.85em', lg: '1em', xl: '1em' },
                                            }}>{nomorPhone2 == '' ? '-' : nomorPhone2}</Typography>
                                        </Box>
                                        <Box sx={{ mt: '0.5em' }}>
                                            <Typography sx={{
                                                fontWeight: 'bold',
                                                fontFamily: 'Open Sans',
                                                fontSize: { xs: '0.55em', sm: '0.6em', md: '0.65em', lg: '0.7em', xl: '0.7em' },
                                            }}>Email</Typography>
                                            <Typography sx={{
                                                fontFamily: 'Open Sans',
                                                fontSize: { xs: '0.75em', sm: '0.8em', md: '0.85em', lg: '1em', xl: '1em' },
                                            }}>{emailAddress}</Typography>
                                        </Box>
                                    </Box>
                                )}
                                <Box sx={{ mb: 2 }}>
                                    <Button
                                        size='medium'
                                        variant="contained"
                                        onClick={handleNext}
                                        sx={{ mt: 1, mr: 1 }}
                                        disabled={loading}
                                    >
                                        {index === steps.length - 1 
                                            ? loading?"Loading":"Submit"
                                            : 'Continue'}
                                    </Button>
                                    {index != 0 && !loading && (<Button size='medium' onClick={handleBack} sx={{ mt: 1, mr: 1 }}>Back</Button>)}
                                </Box>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
            </Box>
        </Stack >
    );
};