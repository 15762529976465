import React from 'react';


// Internal Import

// External Import
import axios, { AxiosResponse } from 'axios';
import { ReactSession } from 'react-client-session';
import { Carousel } from 'react-responsive-carousel';
import { FooterComponent } from '../../components/footer';
import { HelmetComponent } from '../../components/helmet';
import { useNavigate, useParams } from 'react-router-dom';

// MUI Import
import { Box, Grid, Skeleton, Stack, Typography } from '@mui/material';
import { SnackbarComponent, SnackbarProps, StatusProps } from '../../components/snackbar';
import { AppbarAccountComponent } from '../../components/appbar/account';
import { ClusterDataBeanRes, LoginMemberDataRes, SliderDataBeanRes, SubclusterDataRes, SubclusterRes } from '../../utils/structure/output';


interface SubclusterScreenProps { }
export const SubclusterScreen: React.FC<SubclusterScreenProps> = (props) => {

    let { p_id, t_id, c_id } = useParams();
    let navigate = useNavigate();

    const [account, setAccount] = React.useState<undefined | LoginMemberDataRes>(undefined);
    React.useEffect(() => { }, []);

    const [loading, setLoading] = React.useState<boolean>(true);

    const [subcluster, setSubcluster] = React.useState<null | SubclusterDataRes>(null)

    // Snackbar
    const [snackbar, setSnackbar] = React.useState<SnackbarProps>({ open: false, status: undefined, message: '' });
    const handleOpenSnackbar = (newStatus: StatusProps, newMessage: string, time: number) => {
        setSnackbar({ open: true, status: newStatus, message: newMessage });
        setTimeout(() => { handleCloseSnackbar(); }, 5000);
    };
    const handleCloseSnackbar = () => { setSnackbar({ open: false, status: undefined, message: '' }); };

    const getData = async (dtAccount: LoginMemberDataRes) => {
        try {
            const response: AxiosResponse = await axios.get(process.env.REACT_APP_API_BASE_URL!+process.env.REACT_APP_PATH_SUBCLUSTER!+'?p_id='+p_id+'&t_id='+t_id+'&c_id='+c_id, {
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', Authorization: 'Bearer ' + dtAccount.token },
            });

            let dtLoad: SubclusterRes = response.data;
            if (dtLoad.status == 200) {
                setSubcluster(dtLoad.data);
                setLoading(false);
            }
        } catch (error) {
            if (axios.isAxiosError(error) && error.response?.status === 401) {
                setAccount(undefined);
                ReactSession.set(process.env.REACT_APP_AUTH, undefined);
                handleOpenSnackbar('error', "Unauthorized request, please login again!", 3000);
                navigate('/login', { replace: true });
            } else {
                handleOpenSnackbar('error', "Something problem, please refresh your broweser!", 3000);
            }
        }
    }

    const handleClickItemCluster = (item: ClusterDataBeanRes) => {
        navigate('/'+item.next_page+'/'+p_id+'/'+c_id+'/'+item.id);
    }

    React.useEffect(() => {
        let dtAccount: LoginMemberDataRes = ReactSession.get(process.env.REACT_APP_AUTH)
        setAccount(dtAccount);
        getData(dtAccount);
    }, [])




    return (
        <Stack sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            paddingBottom: { xs: '3.4em', sm: '3.6em', md: '3.8em', lg: '4em', xl: '4.2em' },
        }}>

            <HelmetComponent />
            <SnackbarComponent snackbar={snackbar} handleCloseSnackbar={handleCloseSnackbar} />
            <AppbarAccountComponent page={'home'} account={account} setAccount={setAccount} />

            {loading&&(
                <Skeleton variant='rectangular' sx={{
                    width: '100%',
                    height: { xs: '8em', sm: '10.5em', md: '15em', lg: '17.75em', xl: '17.75em' }
                }}/>
            )}
            {subcluster != null && subcluster?.sliders != null && subcluster.sliders.length > 1 && (
                <Carousel
                    autoPlay
                    infiniteLoop
                    dynamicHeight
                    showStatus={false}
                    showThumbs={false}
                >
                    {subcluster.sliders.map((item: SliderDataBeanRes, index: number) => {
                        return (
                            <div>
                                <Box
                                    key={index}
                                    component='img'
                                    alt={item.name}
                                    src={item.image}
                                    sx={{
                                        width: '100%',
                                        height: { xs: '12em', sm: '17em', md: '26em', lg: '33.5em', xl: '33.5em' }
                                    }}
                                />
                            </div>
                        );
                    })}
                </Carousel>
            )}

            {subcluster != null && subcluster?.sliders != null && subcluster.sliders.length === 1 && (
                <Box
                    component='img'
                    alt={subcluster.sliders[0].name}
                    src={subcluster.sliders[0].image}
                    sx={{
                        width: '100%',
                        height: { xs: '12em', sm: '17em', md: '26em', lg: '33.5em', xl: '33.5em' }
                    }}
                />
            )}


            {loading&&(
                <Skeleton variant='rectangular' sx={{ width: '100%', height: '7em'}} />
            )}
            {subcluster != null && subcluster?.subclusters != null && subcluster.subclusters.length > 0 && (
                <Box sx={{
                    pl: '5%',
                    pr: '5%',
                    width: '90%',
                    backgroundColor: '#ffffff',
                    py: { xs: '1em', sm: '1.5em', md: '1.75em', lg: '2.5em', xl: '2.5em' },
                }}>
                    <Typography sx={{
                        mb: '1.5em',
                        color: '#000000',
                        fontWeight: 'bold',
                        fontFamily: 'Roboto',
                        fontSize: { xs: '0.8em', sm: '0.9em', md: '1em', lg: '1.25em', xl: '1.25em' },
                    }}>{'Discover ' + subcluster?.name_cluster}</Typography>
                    <Grid container spacing={4}>
                        {subcluster.subclusters.map((item: ClusterDataBeanRes, index: number) => {
                            return (
                                <Grid key={index} item xs={6} sm={4} md={3}>
                                    <Box
                                        onClick={() => handleClickItemCluster(item)}
                                        sx={{
                                            display: 'flex',
                                            cursor: 'pointer',
                                            alignItems: 'center',
                                            borderRadius: '0.5em',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            backgroundColor: '#ffffff',
                                            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px',
                                        }}
                                    >
                                        <Box
                                            key={index}
                                            component='img'
                                            alt={item.name}
                                            src={item.image}
                                            sx={{ width: '80%' }}
                                        />
                                        {item.price!=""&& ( 
                                            <Typography sx={{
                                                my: '1em',
                                                color: '#000000',
                                                fontFamily: 'Roboto',
                                                textTransform: 'capitalize',
                                                fontSize: { xs: '0.6em', sm: '0.6em', md: '0.7em', lg: '1em', xl: '1em' },
                                            }}>{item.price}</Typography>
                                        )}
                                    </Box>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Box>
            )}
            
            <FooterComponent />

        </Stack>
    );
};