import React from 'react';


// Internal Import
import { HelmetComponent } from '../../../components/helmet';

// External Import
import { Form, Field, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { ReactSession } from 'react-client-session';
import axios, { AxiosError, AxiosResponse } from 'axios';


// MUI Import
import { Box, Modal, Stack, Button, TextField, Typography } from '@mui/material';
import { LoginMemberReq } from '../../../utils/structure/input';
import { DefaultRes, LoginMemberDataRes, LoginMemberRes } from '../../../utils/structure/output';
import { SnackbarComponent, SnackbarProps, StatusProps } from '../../../components/snackbar';

// Image Import
const imgInterior = `/assets/login_right.png`;
const imgLogo = `/assets/logo_potrait_${process.env.REACT_APP_PROJECT_CODE}.png`;
// const imgLogo = `/assets/logo_landscape_${process.env.REACT_APP_PROJECT_CODE}.png`;

interface LoginFormValues { user: string }

interface LoginMemberScreenProps { }
export const LoginMemberScreen: React.FC<LoginMemberScreenProps> = (props) => {

    let navigate = useNavigate();

    const [loading, setLoading] = React.useState<boolean>(false);

    const [account, setAccount] = React.useState<undefined|LoginMemberDataRes>(undefined);

    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const handelOpenModal = () => { setOpenModal(true); }
    const handelCloseModal = () => { setOpenModal(false); }

    // Snackbar
    const [snackbar, setSnackbar] = React.useState<SnackbarProps>({ open: false, status: undefined, message: '' });
    const handleOpenSnackbar = (newStatus: StatusProps, newMessage: string) => {
        let count: number = 2000;
        setSnackbar({ open: true, status: newStatus, message: newMessage });
        if (newStatus == 'success') { count = 1000; }
        setTimeout(() => { handleCloseSnackbar(); }, count);
    };
    const handleCloseSnackbar = () => { setSnackbar({ open: false, status: undefined, message: '' }); };


    const initialValues: LoginFormValues = { user: '' };
    const handleFormSubmit = (values: LoginFormValues) => {
        if (!values.user) {
            handleOpenSnackbar('error', 'Email is required');
            return;
        }
        setLoading(true);
        let params: LoginMemberReq = { 
            user: values.user, 
            code_project: process.env.REACT_APP_PROJECT_CODE!,
            ereg_project: process.env.REACT_APP_PROJECT_ID_EREG!,
            citralink_project: process.env.REACT_APP_PROJECT_ID_CTRLNK!,
        }
        postData(params);
    };

    const postData = async (requestData: LoginMemberReq) => {
        try {
            let url: string = process.env.REACT_APP_API_BASE_URL!+process.env.REACT_APP_PATH_LOGIN!;
            const response: AxiosResponse = await axios.post(url, requestData, { headers: { 
                'Accept': 'application/json',  'Content-Type': 'application/json' 
            }});

            setLoading(false);
            let loginResponse: LoginMemberRes = response.data;
            if (loginResponse.data.is_update === 1) {
                handleOpenSnackbar('warning', "Update status");
                navigate('/status-member/'+loginResponse.data.mid)
                // window.open("http://eregcr.citraraya.com/eRegCRAdmin/auth/api?act=statusMemberId&mid=" + loginResponse.data.mid, "_self");
                return;
            }
            setAccount(loginResponse.data);
            handelOpenModal();
            setTimeout(() => {
                if (openModal) { handleCorrect(); }
            }, 3000);
        } catch (error) {
            setLoading(false);
            const axiosError = error as AxiosError;
            let errResponse: DefaultRes | any = axiosError.response?.data;
            handleOpenSnackbar('error', errResponse?.message);
        }
    }

    const handleRegister = () => {
        // window.open("https://citra-link.com/cl-register.php", "_self");
        navigate('/register');
    }

    const handleCorrect = () => {
        ReactSession.set(process.env.REACT_APP_AUTH, account);
        handelCloseModal();
        setAccount(undefined);
        navigate('/', {replace: true});
    }

    const handleLupaMember = () => {
        navigate('/check-member');
    }

    return (
        <Stack sx={{
            height: '100vh',
            maxWidth: '100vw',
            flexDirection: 'row',
        }}>
            <HelmetComponent />
            <SnackbarComponent snackbar={snackbar} handleCloseSnackbar={handleCloseSnackbar} />


            <Stack sx={{
                flex: 1,
                flexDirection: 'column',
                backgroundColor: '#F1F1F7',
            }}>
                <Box sx={{
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    backgroundColor: '#EDEDF5',
                    mt: { xs: '0em', sm: '0em', md: '0em', lg: '3em' },
                    mb: { xs: '0em', sm: '0em', md: '0em', lg: '3em' },
                    ml: { xs: '0em', sm: '0em', md: '0em', lg: '3em' },
                    boxShadow: {
                        md: "0 0 8 0 rgba(0,0,0,0.1) inset",
                        xs: "0 0 8px 0 rgba(0,0,0,0.1) inset",
                        sm: "0 0 8px 0 rgba(0,0,0,0.1) inset",
                        lg: "0 14px 6px -14px rgba(0,0,0,0.1) inset, 0 -14px 6px -14px rgba(0,0,0,0.1) inset, 14px 0 6px -14px rgba(0,0,0,0.1) inset"
                    }
                }}>
                    <Box 
                        component="img" 
                        alt="Image Interior" 
                        src={process.env.PUBLIC_URL+imgLogo} 
                        sx={{ mb: '2em', height: '7em', objectFit: 'contain' }} 
                    />

                    <Formik onSubmit={handleFormSubmit} initialValues={initialValues}>
                        <Form>
                            <Box sx={{
                                px: '1.5em',
                                pt: '1.5em',
                                pb: '1.8em',
                                borderRadius: '0.4em',
                                backgroundColor: '#ffffff',
                                width: { xs: 240, sm: 250, md: 330, lg: 350 }
                            }}>
                                <Field
                                    focused
                                    fullWidth
                                    type="text"
                                    name="user"
                                    label="Email/Phone"
                                    color="success"
                                    as={TextField}
                                    variant="standard"
                                    placeholder="Enter Email/Phone"
                                    sx={{ width: { xs: 240, sm: 250, md: 330, lg: 350 } }}
                                />
                                {/* <Field
                                    focused
                                    fullWidth
                                    as={TextField}
                                    color="success"
                                    name="password"
                                    type="password"
                                    label="Password"
                                    variant="standard"
                                    placeholder="Enter password"
                                    sx={{ mt: '1em', width: { xs: 240, sm: 250, md: 330, lg: 350 } }}
                                /> */}
                            </Box>

                            <Box sx={{ mt: '2.5em' }}>
                                <Button
                                    fullWidth
                                    size='medium'
                                    type="submit"
                                    color='success'
                                    disabled={loading}
                                    variant="contained"
                                >Login</Button>
                            </Box>
                            <Box sx={{ mt: '0.75em' }}>
                                <Button
                                    fullWidth
                                    size='medium'
                                    type='button'
                                    color='primary'
                                    variant="contained"
                                    onClick={handleRegister}
                                >Daftar Member</Button>
                            </Box>
                            <Typography sx={{
                                mt: '1.5em',
                                fontWeight: '500',
                                textAlign: 'right',
                                fontFamily: 'Roboto',
                                fontSize: { xs: '0.7em', sm: '0.75em', md: '0.8em', lg: '0.85em', xl: '0.95em' },
                            }}>
                                Lupa member terdaftar? <span onClick={handleLupaMember} style={{
                                    color: '#008060',
                                    cursor: 'pointer',
                                    textDecoration: 'underline',
                                }}>Klik Disini</span>
                            </Typography>
                        </Form>
                    </Formik>



                </Box>
            </Stack>
            <Stack sx={{
                flex: 0.6,
                flexDirection: 'column',
                backgroundColor: '#ffffff',
                display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex' }
            }}>
                <Box sx={{
                    flex: 1,
                    mt: '3em',
                    mb: '3em',
                    mr: '3em',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    backgroundColor: '#ffffff',
                    justifyContent: 'space-around',
                    boxShadow: "0 14px 6px -14px rgba(0,0,0,0.1) inset, 0 -14px 6px -14px rgba(0,0,0,0.1) inset, -14px 0 6px -14px rgba(0,0,0,0.1) inset"
                }}>
                    <Box 
                        component="img" 
                        alt="Image Interior"
                        sx={{ width: '78%' }}
                        src={process.env.PUBLIC_URL+imgInterior} 
                    />

                    {/* <Typography sx={{
                        width: '75%',
                        color: '#000000',
                        fontWeight: '400',
                        textAlign: 'center',
                        fontFamily: 'Roboto',
                        fontSize: { lg: '0.8em', xl: '1em' },
                    }}>PT. Ciputra Residence<br />Jl. CitraRaya Boulevard, Tangerang 15710<br />Telp: (+62) 021-5960888 - Fax: (+62) 021-5961060</Typography> */}
                    <Typography sx={{
                        width: '75%',
                        color: '#000000',
                        fontWeight: '600',
                        textAlign: 'center',
                        fontFamily: 'Roboto',
                        fontSize: { xs: '0.6em', sm: '0.7em', md: '0.85em', xl: '0.9em' },
                    }}>{`©Copyright ${new Date().getFullYear()} - ${process.env.REACT_APP_PROJECT_NAME}`}</Typography>
                </Box>
            </Stack>

            <Modal
                open={openModal}
                onClose={handelCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    px: '1em',
                    pt: '1em',
                    pb: '1.4em',
                    top: '50%',
                    left: '50%',
                    boxShadow: 24,
                    border: 'none',
                    outline: 'none',
                    position: 'absolute',
                    borderRadius: '0.25em',
                    bgcolor: 'background.paper',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: '70%', sm: '50%', md: '30%', lg: '25%', xl: '25%' },
                }}>
                    <Typography>
                        <table>
                            <tr>
                                <td colSpan={3} style={{
                                    fontSize: '1.5em',
                                    fontWeight: 'bold',
                                    paddingBottom: '1em',
                                    fontFamily: 'Open Sans',
                                }}>Information Account</td>
                            </tr>
                            <tr style={{
                                fontSize: '0.9em',
                                paddingBottom: '1.75em',
                                fontFamily: 'Open Sans',
                            }}>
                                <td>Nama</td>
                                <td>:</td>
                                <td>{account?.fullname}</td>
                            </tr>
                            <tr style={{
                                fontSize: '0.9em',
                                paddingBottom: '1.75em',
                                fontFamily: 'Open Sans',
                            }}>
                                <td>Kantor</td>
                                <td>:</td>
                                <td>{account?.office}</td>
                            </tr>
                        </table>
                    </Typography>
                    <Box sx={{ mt: '2em' }}>
                        <Button
                            fullWidth
                            size='large'
                            type='button'
                            color='success'
                            variant="contained"
                            onClick={handleCorrect}
                        >Submit</Button>
                    </Box>
                </Box>
            </Modal>
        </Stack>
    );
};