
import React from "react";
import { AppBar, Box } from "@mui/material";

interface AppbarComponentProps {}
export const AppbarComponent: React.FC<AppbarComponentProps> = (props) => {
    const [scrolled, setScrolled] = React.useState<boolean>(false);
    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 50) { setScrolled(true) }
        else if (scrolled <= 50) { setScrolled(false) }
    };
    window.addEventListener('scroll', toggleVisible);


    const imgLogoLandscape = `/assets/logo_landscape_${process.env.REACT_APP_PROJECT_CODE}.png`;

    return (
        <Box sx={{ marginBottom: '3.9em' }}>
            <AppBar position="fixed" component='nav' style={{
                background: '#fff',
                alignItems: 'center',
                justifyContent: 'center',
                boxShadow: scrolled ? "0 3px 5px rgba(57, 63, 72, 0.3)" : "unset"
            }}>
                <Box sx={{
                    // mx: '2.5em',
                    width: '90%',
                    height: '3.8em',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    {process.env.REACT_APP_PROJECT_CODE=="cmr"?(
                        <Box 
                            component="img" 
                            src={imgLogoLandscape} 
                            sx={{ height: '3em' }} 
                            alt="Image Logo Landscape" 
                        />
                    ):(
                        <Box component="img" src={imgLogoLandscape} alt="Image Logo Landscape" sx={{
                            height: { xs: '2.1em', sm: '2.3em', md: '2.4em', lg: '2.7em', xl: '2.8em' }
                        }} />
                    )}
                </Box>
            </AppBar>
        </Box>
    );
};