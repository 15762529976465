import React from "react";

// Internal Import

// External Import
import { Box, Typography } from "@mui/material";
import StarIcon from '@mui/icons-material/Star';
import { LoginMemberDataRes, NupDataBeanRes } from "../../../utils/structure/output";
import { env } from "process";

interface AccordionNUPComponentProps {
    nup: NupDataBeanRes
    idNup: null | number
    account: undefined | LoginMemberDataRes
    handleIdNup: (value: null | number) => void
}
export const AccordionNUPComponent: React.FC<AccordionNUPComponentProps> = ({ nup, idNup, account, handleIdNup }) => {
    const handleItemAccordion = () => {
        if (idNup === nup.id) { handleIdNup(null); }
        else { handleIdNup(nup.id); }
    }
    const handleDaftarItemAccordion = () => {
        if (nup.link != "") {
            window.open(nup.link + "&eventReffId="+nup.event_reff_id+"&memberIdProyek="+account?.id_member+"&proyekId="+process.env.REACT_APP_PROJECT_ID_EREG!, "_self");
        }
        return
    }

    return (
        <Box sx={{ width: '80%' }}>
            <Box
                onClick={handleItemAccordion}
                sx={{
                    my: '0.5em',
                    display: 'flex',
                    cursor: 'pointer',
                    borderRadius: '10em',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#008060',
                    px: { xs: '1em', sm: '1.25', md: '1.5m', lg: '2em', xl: '2em' },
                    py: { xs: '0.5em', sm: '0.6', md: '0.75em', lg: '0.85em', xl: '0.85em' },
                }}
            >
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: '1.2em',
                    justifyContent: 'center',
                    backgroundColor: '#000000',
                    mr: { xs: '0.3em', sm: '0.3em', md: '0.4em', lg: '0.5em', xl: '0.5em' },
                    width: { xs: '0.75em', sm: '1em', md: '1.25em', lg: '1.5em', xl: '1.5em' },
                    height: { xs: '0.75em', sm: '1em', md: '1.25em', lg: '1.5em', xl: '1.5em' },
                }}>
                    <StarIcon sx={{
                        color: '#ffffff',
                        width: { xs: '0.35em', sm: '0.45em', md: '0.55em', lg: '0.7em', xl: '0.7em' },
                        height: { xs: '0.35em', sm: '0.45em', md: '0.55em', lg: '0.7em', xl: '0.7em' },
                    }} />
                </Box>
                <Typography sx={{
                    color: '#ffffff',
                    fontFamily: 'Roboto',
                    fontSize: { xs: '0.5em', sm: '0.6em', md: '0.7em', lg: '0.8em', xl: '0.8em' },
                }}>{nup.name}</Typography>
            </Box>
            {idNup === nup.id && (
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mb: '1em'
                }}>
                    <Box
                        onClick={handleDaftarItemAccordion}
                        sx={{
                            my: '0.5em',
                            width: '80%',
                            display: 'flex',
                            cursor: 'pointer',
                            borderRadius: '10em',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: '#008060',
                            px: { xs: '0.5em', sm: '0.75', md: '1m', lg: '1.5em', xl: '1.5em' },
                            py: { xs: '0.35em', sm: '0.4em', md: '0.45em', lg: '0.65em', xl: '0.65em' },
                        }}
                    >
                        <Typography sx={{ fontSize: '0.8em', fontFamily: 'Roboto', color: '#ffffff' }}>Daftar</Typography>
                    </Box>
                </Box>
            )}
        </Box>
    );
};