import React from 'react';


// Internal Import
import { AppbarComponent } from '../../components/appbar';
import { AccordionDetailComponent } from '../../components/accordion/detail';
import { SnackbarComponent, SnackbarProps, StatusProps } from '../../components/snackbar';
import { AccordionDataBeenRes, SharingDataRes, SharingRes } from '../../utils/structure/output';

// External Import
import axios, { AxiosResponse } from 'axios';
import { useParams } from 'react-router-dom';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { HelmetComponent } from '../../components/helmet';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';

// MUI Import
import { Box, Skeleton, Stack, Typography } from '@mui/material';


interface SharingScreenProps { }
export const SharingScreen: React.FC<SharingScreenProps> = (props) => {

    let { p_id, c_id, sc_id, m_id } = useParams();

    const [loading, setLoading] = React.useState<boolean>(true);

    const [nameCluster, setNameCluster] = React.useState<string>("");
    const [sharing, setSharing] = React.useState<null | SharingDataRes>(null)

    const [idDetail, setIdDetail] = React.useState<null | number>(null);
    const handleIdDetail = (value: null | number) => { setIdDetail(value); }
    
    // Snackbar
    const [snackbar, setSnackbar] = React.useState<SnackbarProps>({ open: false, status: undefined, message: '' });
    const handleOpenSnackbar = (newStatus: StatusProps, newMessage: string, time: number) => {
        setSnackbar({ open: true, status: newStatus, message: newMessage });
        setTimeout(() => { handleCloseSnackbar(); }, 5000);
    };
    const handleCloseSnackbar = () => { setSnackbar({ open: false, status: undefined, message: '' }); };

    const getData = async () => {
        try {
            const response: AxiosResponse = await axios.get(process.env.REACT_APP_API_BASE_URL! + process.env.REACT_APP_PATH_SHARING! + '?p_id=' + p_id + '&c_id=' + c_id + "&sc_id=" + sc_id + "&m_id=" + m_id, {
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
            });

            let dtLoad: SharingRes = response.data;
            if (dtLoad.status == 200) {
                setSharing(dtLoad.data);
                if (dtLoad.data.name_sub_cluster != "") {
                    setNameCluster(dtLoad.data.name_sub_cluster);
                } else {
                    setNameCluster(dtLoad.data.name_cluster);
                }
                setLoading(false);
            }
        } catch (error) {
            handleOpenSnackbar('error', "Something problem, please refresh your broweser!", 3000);
        }
    }

    React.useEffect(() => {
        getData();
    }, []);

    const handlePhone = () => { window.open("tel:"+sharing?.phone_sales, "_blank"); }
    const handleWhatsapp = () => {
        window.open("https://api.whatsapp.com/send?phone="+sharing?.phone_sales+"&text=Halo "+sharing?.name_sales+" Saya ingin mendapatkan informasi lebih detil produk "+nameCluster+" di "+sharing?.name_project, "_blank");
    }


    return (
        <Stack sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            paddingBottom: { xs: '3.4em', sm: '3.6em', md: '3.8em', lg: '4em', xl: '4.2em' },
        }}>

            <HelmetComponent />
            <SnackbarComponent snackbar={snackbar} handleCloseSnackbar={handleCloseSnackbar} />

            <AppbarComponent />

            {loading && (
                <Skeleton variant='rectangular' sx={{
                    width: '100%',
                    height: { xs: '8em', sm: '10.5em', md: '15em', lg: '17.75em', xl: '17.75em' }
                }} />
            )}
            {sharing != null && sharing?.banner_image != "" && (
                <Box
                    component='img'
                    alt={sharing.banner_image}
                    src={sharing.banner_image}
                    sx={{
                        width: '100%',
                        height: { xs: '10em', sm: '17em', md: '26em', lg: '33.5em', xl: '33.5em' }
                    }}
                />
            )}


            {loading && (
                <Skeleton variant='rectangular' sx={{ width: '100%', height: '7em' }} />
            )}
            {sharing != null && sharing?.accordions != null && sharing.accordions.length > 0 && (
                <>
                    <Box sx={{
                        pl: '5%',
                        pr: '5%',
                        width: '90%',
                        backgroundColor: '#ffffff',
                        py: { xs: '1em', sm: '1.5em', md: '1.75em', lg: '2.5em', xl: '2.5em' },
                    }}>
                        <Typography sx={{
                            mb: '1.5em',
                            color: '#000000',
                            fontWeight: 'bold',
                            fontFamily: 'Roboto',
                            fontSize: { xs: '1em', sm: '1.15em', md: '1.25em', lg: '1.5em', xl: '1.5em' },
                        }}>{'Welcome to ' + nameCluster}</Typography>
                        {sharing.accordions.map((item: AccordionDataBeenRes, index: number) => {
                            return (
                                <AccordionDetailComponent
                                    cl={item}
                                    key={index}
                                    data={sharing}
                                    idDetail={idDetail}
                                    handleIdDetail={handleIdDetail}
                                />
                            )
                        })}
                    </Box>
                    <Box sx={{ pl: '5%', pr: '5%', width: '90%', }}>
                        <Typography sx={{
                            mt: '2.5em',
                            fontSize: '1.15em',
                            fontFamily: 'Roboto'
                        }}>
                            Segera hubungi <span style={{ fontWeight: 'bold' }}>{sharing.name_sales}</span> untuk mendapatkan informasi lebih lanjut mengenai Produk, Promo Pembelian, dan Jadwal Kunjungan ke Lokasi.
                        </Typography>
                        <Typography sx={{
                            mt: '1.5em',
                            fontSize: '1em',
                            fontFamily: 'Roboto'
                        }}>Klik salah satu link di bawah ini</Typography>
                        <Box sx={{
                            flex: 1,
                            mt: '1em',
                            display: 'flex',
                            flexDirection: 'row',
                        }}>
                            <Box
                                onClick={handlePhone}
                                sx={{
                                    flex: 1,
                                    mr: '0.5em',
                                    display: 'flex',
                                    cursor: 'pointer',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    borderRadius: '0.75em',
                                    justifyContent: 'center',
                                    backgroundColor: '#ffffff',
                                    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px',
                                    px: { xs: '1em', sm: '1.25', md: '1.5m', lg: '2em', xl: '2em' },
                                    py: { xs: '0.5em', sm: '0.6', md: '0.75em', lg: '1em', xl: '1em' },
                                }}
                            >
                                <PhoneInTalkIcon sx={{
                                    color: '#000000',
                                    width: { xs: '0.4em', sm: '0.5em', md: '0.6em', lg: '0.8em', xl: '0.8em' },
                                    height: { xs: '0.4em', sm: '0.5em', md: '0.6em', lg: '0.8em', xl: '0.8em' },
                                }} />
                                <Typography sx={{
                                    ml: '0.5em',
                                    fontWeight: 700,
                                    color: '#000000',
                                    fontFamily: 'Roboto',
                                    textTransform: 'capitalize',
                                    fontSize: { xs: '0.5em', sm: '0.6em', md: '0.75em', lg: '1em', xl: '1em' }
                                }}>Phone Call</Typography>
                            </Box>
                            <Box
                                onClick={handleWhatsapp}
                                sx={{
                                    flex: 1,
                                    ml: '0.5em',
                                    display: 'flex',
                                    cursor: 'pointer',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    borderRadius: '0.75em',
                                    justifyContent: 'center',
                                    backgroundColor: '#008060',
                                    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px',
                                    px: { xs: '1em', sm: '1.25', md: '1.5m', lg: '2em', xl: '2em' },
                                    py: { xs: '0.5em', sm: '0.6', md: '0.75em', lg: '1em', xl: '1em' },
                                }}
                            >
                                <WhatsAppIcon sx={{
                                    color: '#ffffff',
                                    width: { xs: '0.4em', sm: '0.5em', md: '0.6em', lg: '0.8em', xl: '0.8em' },
                                    height: { xs: '0.4em', sm: '0.5em', md: '0.6em', lg: '0.8em', xl: '0.8em' },
                                }} />
                                <Typography sx={{
                                    ml: '0.5em',
                                    fontWeight: 700,
                                    color: '#ffffff',
                                    fontFamily: 'Roboto',
                                    textTransform: 'capitalize',
                                    fontSize: { xs: '0.5em', sm: '0.6em', md: '0.75em', lg: '1em', xl: '1em' }
                                }}>Whatsapp Chat</Typography>
                            </Box>
                        </Box>
                    </Box>
                </>
            )}

        </Stack>
    );
};