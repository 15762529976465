import React from 'react';

// Internal Import
import { RegisterMemberDataRes } from '../../../utils/structure/output';

// MUI Import
import { Box, Modal, Button } from '@mui/material';


// Image Import

export type ModalProofOfRegComponentProps = {
    open: boolean
    handleClose: ()=>void
    handleDownload: ()=>void
    account: RegisterMemberDataRes
}
export const ModalProofOfRegComponent: React.FC<ModalProofOfRegComponentProps> = ({open, account, handleClose, handleDownload}) => {

    return (

        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                px: '1em',
                pt: '1em',
                pb: '1.4em',
                top: '50%',
                left: '50%',
                boxShadow: 24,
                border: 'none',
                outline: 'none',
                position: 'absolute',
                borderRadius: '0.25em',
                bgcolor: 'background.paper',
                transform: 'translate(-50%, -50%)',
                width: { xs: '80%', sm: '80%', md: '70%', lg: '50%', xl: '40%' },
            }}>
                <table style={{width: '100%'}}>
                    <tr>
                        <td colSpan={3} style={{
                            fontSize: '1.5em',
                            fontWeight: 'bold',
                            paddingBottom: '1em',
                            fontFamily: 'Open Sans',
                        }}>Bukti Registrasi Member</td>
                    </tr>
                    <tr style={{
                        fontSize: '0.9em',
                        fontFamily: 'Open Sans',
                    }}>
                        <td>Nama Sales</td>
                        <td>:</td>
                        <td>{account?.fullname}</td>
                    </tr>
                    <tr style={{
                        fontSize: '0.9em',
                        fontFamily: 'Open Sans',
                    }}>
                        <td>No KTP Sales</td>
                        <td>:</td>
                        <td>{account?.ktp_number}</td>
                    </tr>
                    <tr style={{
                        fontSize: '0.9em',
                        fontFamily: 'Open Sans',
                    }}>
                        <td>Nomor HP 1</td>
                        <td>:</td>
                        <td>{account?.phone_number1}</td>
                    </tr>
                    <tr style={{
                        fontSize: '0.9em',
                        fontFamily: 'Open Sans',
                    }}>
                        <td>Nomor HP 2</td>
                        <td>:</td>
                        <td>{account?.phone_number2}</td>
                    </tr>
                    <tr style={{
                        fontSize: '0.9em',
                        fontFamily: 'Open Sans',
                    }}>
                        <td>Email</td>
                        <td>:</td>
                        <td>{account?.email_address}</td>
                    </tr>
                    <tr style={{
                        fontSize: '0.9em',
                        fontFamily: 'Open Sans',
                    }}>
                        <td>Nama Kantor</td>
                        <td>:</td>
                        <td>{account?.office}</td>
                    </tr>
                    <tr>
                        <td colSpan={3} style={{
                            fontSize: '0.9em',
                            fontFamily: 'Open Sans',
                            paddingTop: '1.75em',
                        }}>Terimakasih telah melakukan Registrasi Member</td>
                    </tr>
                    {/* {account?.principal_name != "" ? (
                        <tr>
                            <td colSpan={3} style={{
                                fontSize: '0.9em',
                                paddingBottom: '1.75em',
                                fontFamily: 'Open Sans',
                            }}>
                                Member anda akan aktif dan dapat digunakan untuk mengakses Citralink setelah <b>{account?.principal_name}</b> sebagai Principal <b>{account?.office}</b> melakukan aktivasi Member anda melalui link yang telah dikirim ke alamat email bersangkutan.
                            </td>
                        </tr>
                    ) : (
                        <tr>
                            <td colSpan={3} style={{
                                fontSize: '0.9em',
                                paddingBottom: '1.75em',
                                fontFamily: 'Open Sans',
                            }}>
                                Untuk aktivasi Member Anda. Kami telah mengirimkan Link Aktivasi ke nomor Handphone <b>{account?.phone_number1}</b>, silahkan klik Link tersebut untuk dapat mulai mengakses Citralink.
                            </td>
                        </tr>
                    )} */}
                    <tr>
                        <td colSpan={3} style={{
                            fontSize: '0.9em',
                            paddingBottom: '0.75em',
                            fontFamily: 'Open Sans',
                        }}>Silahkan Download Bukti Registrasi Member.</td>
                    </tr>
                </table>
                <Box>
                    <Button
                        fullWidth
                        size='medium'
                        type='button'
                        color='warning'
                        variant="contained"
                        onClick={handleDownload}
                    >Download</Button>
                </Box>
            </Box>
        </Modal>
    );
};