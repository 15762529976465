import React from 'react';


// Internal Import
import { FooterComponent } from '../../components/footer';
import { HelmetComponent } from '../../components/helmet';
import { SnackbarComponent, SnackbarProps, StatusProps } from '../../components/snackbar';
import { AccordionDetailComponent } from '../../components/accordion/detail';
import { AccordionDataBeenRes, DetailDataRes, DetailRes, LoginMemberDataRes } from '../../utils/structure/output';

// External Import
import axios, { AxiosResponse } from 'axios';
import { ReactSession } from 'react-client-session';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useNavigate, useParams } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';

// MUI Import
import { Box, Button, Grid, Modal, Skeleton, Stack, Typography } from '@mui/material';
import { AppbarAccountComponent } from '../../components/appbar/account';


interface DetailScreenProps { }
export const DetailScreen: React.FC<DetailScreenProps> = (props) => {

    let { p_id, c_id, sc_id } = useParams();
    let navigate = useNavigate();

    
    const [nameCluster, setNameCluster] = React.useState<string>("");

    const [account, setAccount] = React.useState<undefined | LoginMemberDataRes>(undefined);
    React.useEffect(() => { }, []);

    const [loading, setLoading] = React.useState<boolean>(true);

    const [detail, setDetail] = React.useState<null | DetailDataRes>(null)

    const [idDetail, setIdDetail] = React.useState<null | number>(null);
    const handleIdDetail = (value: null | number) => { setIdDetail(value); }

    // Modal state
    const [linkShare, setLinkShare] = React.useState<string>("");
    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const handelOpenModal = () => { setOpenModal(true); }
    const handelCloseModal = () => { setOpenModal(false); }
    const handleShareToWa = () => { 
        // params => projectid, cluster_id, sub_cluster_id and mid
        window.open("https://api.whatsapp.com/send?text=Gunakan link "+ linkShare + " untuk melihat brosur dan pricelist " + nameCluster, "_blank");
    }
    const handleCopyLink = () => { navigator.clipboard.writeText(linkShare); }

    // Snackbar
    const [snackbar, setSnackbar] = React.useState<SnackbarProps>({ open: false, status: undefined, message: '' });
    const handleOpenSnackbar = (newStatus: StatusProps, newMessage: string, time: number) => {
        setSnackbar({ open: true, status: newStatus, message: newMessage });
        setTimeout(() => { handleCloseSnackbar(); }, 5000);
    };
    const handleCloseSnackbar = () => { setSnackbar({ open: false, status: undefined, message: '' }); };

    const getData = async (dtAccount: LoginMemberDataRes) => {
        try {
            const response: AxiosResponse = await axios.get(process.env.REACT_APP_API_BASE_URL!+process.env.REACT_APP_PATH_DETAIL!+ '?p_id=' + p_id + '&c_id=' + c_id + '&sc_id=' + sc_id + '&m_id=' + dtAccount.mid, {
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', Authorization: 'Bearer ' + dtAccount.token },
            });

            let dtLoad: DetailRes = response.data;
            if (dtLoad.status == 200) {
                setDetail(dtLoad.data);
                if (dtLoad.data.name_sub_cluster != "") {
                    setNameCluster(dtLoad.data.name_sub_cluster);
                } else {
                    setNameCluster(dtLoad.data.name_cluster);
                }
                setLoading(false);
            }
        } catch (error) {
            if (axios.isAxiosError(error) && error.response?.status === 401) {
                setAccount(undefined);
                ReactSession.set(process.env.REACT_APP_AUTH, undefined);
                handleOpenSnackbar('error', "Unauthorized request, please login again!", 3000);
                navigate('/login', { replace: true });
            } else {
                handleOpenSnackbar('error', "Something problem, please refresh your broweser!", 3000);
            }
        }
    }

    React.useEffect(() => {
        let dtAccount: LoginMemberDataRes = ReactSession.get(process.env.REACT_APP_AUTH)
        setAccount(dtAccount);
        getData(dtAccount);

        let url: string = "http://"+window.location.hostname+":3000/";
        if (window.location.hostname != "localhost") {
            url = "https://"+window.location.hostname+"/"
        }
        setLinkShare(url + "sharing/"+p_id+"/"+c_id+"/"+sc_id+"/"+dtAccount.mid);
    }, []);


    return (
        <Stack sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            paddingBottom: { xs: '3.4em', sm: '3.6em', md: '3.8em', lg: '4em', xl: '4.2em' },
        }}>
            <HelmetComponent />
            <SnackbarComponent snackbar={snackbar} handleCloseSnackbar={handleCloseSnackbar} />
            <AppbarAccountComponent page={'home'} account={account} setAccount={setAccount} />

            {loading && (
                <Skeleton variant='rectangular' sx={{
                    width: '100%',
                    height: { xs: '8em', sm: '10.5em', md: '15em', lg: '17.75em', xl: '17.75em' }
                }} />
            )}
            {detail != null && detail?.banner_image != "" && (
                <Box
                    component='img'
                    alt={detail.banner_image}
                    src={detail.banner_image}
                    sx={{
                        width: '100%',
                        height: { xs: '10em', sm: '17em', md: '26em', lg: '33.5em', xl: '33.5em' }
                    }}
                />
            )}


            {loading && (
                <Skeleton variant='rectangular' sx={{ width: '100%', height: '7em' }} />
            )}
            {detail != null && detail?.accordions != null && detail.accordions.length > 0 && (
                <Box sx={{
                    pl: '5%',
                    pr: '5%',
                    width: '90%',
                    backgroundColor: '#ffffff',
                    py: { xs: '1em', sm: '1.5em', md: '1.75em', lg: '2.5em', xl: '2.5em' },
                }}>
                    <Typography sx={{
                        mb: '1.5em',
                        color: '#000000',
                        fontWeight: 'bold',
                        fontFamily: 'Roboto',
                        fontSize: { xs: '1em', sm: '1.15em', md: '1.25em', lg: '1.5em', xl: '1.5em' },
                    }}>{'Welcome to ' + nameCluster}</Typography>
                    {detail.accordions.map((item: AccordionDataBeenRes, index: number) => {
                        return (
                            <AccordionDetailComponent
                                cl={item}
                                key={index}
                                idDetail={idDetail}
                                data={detail}
                                handleIdDetail={handleIdDetail}
                            />
                        )
                    })}

                    {/* Create product link */}
                    <Box
                        onClick={handelOpenModal}
                        sx={{
                            mt: '4em',
                            mb: '3em',
                            display: 'flex',
                            cursor: 'pointer',
                            borderRadius: '0.5em',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: '#008060',
                            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px',
                            px: { xs: '1em', sm: '1.25', md: '1.5m', lg: '2em', xl: '2em' },
                            py: { xs: '0.5em', sm: '0.6', md: '0.75em', lg: '1em', xl: '1em' },
                        }}
                    >
                        <CreateNewFolderIcon sx={{
                            color: '#ffffff',
                            width: { xs: '0.4em', sm: '0.5em', md: '0.6em', lg: '0.8em', xl: '0.8em' },
                            height: { xs: '0.4em', sm: '0.5em', md: '0.6em', lg: '0.8em', xl: '0.8em' },
                        }} />
                        <Typography sx={{
                            ml: '0.5em',
                            fontWeight: 700,
                            color: '#ffffff',
                            fontFamily: 'Roboto',
                            textTransform: 'capitalize',
                            fontSize: { xs: '0.5em', sm: '0.6em', md: '0.75em', lg: '1em', xl: '1em' }
                        }}>create product link</Typography>
                    </Box>

                </Box>
            )}

            <Modal
                open={openModal}
                onClose={handelCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    py: 3,
                    px: 4,
                    top: '50%',
                    left: '50%',
                    boxShadow: 24,
                    border: 'none',
                    outline: 'none',
                    position: 'absolute',
                    borderRadius: '0.25em',
                    bgcolor: 'background.paper',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: '70%', sm: '50%', md: '30%', lg: '25%', xl: '25%' },
                }}>
                    <CheckCircleIcon sx={{ width: '1.4em', height: '1.4em', color: '#008060' }}/>
                    <Typography sx={{
                        fontSize: '1.3em',
                        fontWeight: 'bold',
                    }}>Successful create product link!</Typography>
                    <Typography sx={{
                        mb: '1.5em',
                        fontSize: '0.8em',
                        fontFamily: 'Roboto',
                    }}>Link berhasil di-generate, simpan dan bagikan link ke customer.</Typography>
                    <Box sx={{
                        flex: 0.8,
                        display: 'flex',
                        flexDirection: 'row'
                    }}>
                        <input
                            disabled
                            value={linkShare}
                            style={{
                                flex: 1,
                                height: '2.3em',
                            }}
                        />
                        <Button
                            type='button'
                            color='success'
                            variant="contained"
                            onClick={handleCopyLink}
                            sx={{ 
                                height: '2.55em',
                                color: '#008060',
                                backgroundColor: '#fff',
                                textTransform: 'capitalize'
                            }}
                        >Copy</Button>
                    </Box>
                    <Box sx={{ mt: '2.5em' }}>
                        <Button
                            fullWidth
                            size='large'
                            type='button'
                            color='success'
                            variant="contained"
                            onClick={handleShareToWa}
                            sx={{ 
                                borderRadius: '10em',
                                backgroundColor: '#008060',
                                textTransform: 'capitalize'
                            }}
                        >
                            <WhatsAppIcon sx={{mr: '0.25em'}} />
                            Bagikan Melalui WhatsApp
                        </Button>
                    </Box>
                </Box>
            </Modal>

            <FooterComponent />

        </Stack>
    );
};