// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#table-kpr {
    border-collapse: collapse;
    width: 100%;
}

#table-kpr thead th, #table-kpr thead tr {
    background-color: #dddddd
}
#table-kpr tbody th,#table-kpr tbody th {
    text-align: left;
}

#table-kpr tbody tr:nth-child(even) {
    background-color: #dddddd;
}`, "",{"version":3,"sources":["webpack://./src/features/table_kpr/index.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI;AACJ;AACA;IACI,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":["#table-kpr {\r\n    border-collapse: collapse;\r\n    width: 100%;\r\n}\r\n\r\n#table-kpr thead th, #table-kpr thead tr {\r\n    background-color: #dddddd\r\n}\r\n#table-kpr tbody th,#table-kpr tbody th {\r\n    text-align: left;\r\n}\r\n\r\n#table-kpr tbody tr:nth-child(even) {\r\n    background-color: #dddddd;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
